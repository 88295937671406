import {useState} from 'react';
import NavBar from '../navbar/NavBar.js'
import Jumbotron from '../jumbotron/Jumbotron.js'
import SearchBar from '../searchBar/SearchBar.js'
import JobsBoard from '../jobsBoard/JobsBoard.js'
import Footer from './Footer.js';

const Main = () => {

  const [flag, setFlag] = useState(false);
  const [jobs, setJobs] = useState([]);

  function loading (){
    if(!flag){
      return <></>
    }else if(flag==="loading"){
      return <div style={{display:"flex", justifyContent:"center", marginBottom:"20px"}}>
        <img alt="Loading icon" src="/images/loading.gif" style={{height:"50px"}} ></img>
      </div>
    }else if(flag){
      return <JobsBoard jobs={jobs}/>
    }
  }

  
  return (
    <div>
      <NavBar />
      <Jumbotron />
      <SearchBar setFlag={setFlag} setJobs={setJobs}/>
      {
        loading()
      }
      <Footer />
    </div>
  )
}

export default Main
